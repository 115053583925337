<template>
  <section class="comprar">
    <!-- Section Imagens -->
    <SectionImagens />
    <!-- Quadro -->
    <div class="quadro">
      <!-- Topo -->
      <div class="topo">
        <div class="texto">
          <h3>Sorteio será realizado em</h3>
          <p>10/02/2025 às 18h30</p>
        </div>
        <div class="tempo">
          <div class="item">
            <h3>{{ state.dias }}</h3>
            <p>Dias</p>
          </div>
          <div class="item">
            <h3>{{ state.horas }}</h3>
            <p>Horas</p>
          </div>
          <div class="item">
            <h3>{{ state.minutos }}</h3>
            <p>Min</p>
          </div>
          <div class="item">
            <h3>{{ state.segundos }}</h3>
            <p>Seg</p>
          </div>
        </div>
      </div>
      <!-- Quantidade -->
      <div class="quantidade">
        <div class="titulo">
          <h3>Escolha sua sorte abaixo</h3>
          <p>Apenas R$ 0,20 por número</p>
        </div>
        <div class="itens">
          <div class="item" v-for="(pacote, index) in state.pacotes" :key="index" :class="{ popular: pacote.popular }">
            <h4 v-if="pacote.popular">POPULAR</h4>
            <div class="icone" :style="`background-image: url('${pacote.icone}')`"></div>
            <h3>{{ pacote.quantidade }}</h3>
            <button>Selecionar</button>
          </div>
        </div>
        <div class="total">
          <button>
            <Svgs nome="menos" />
          </button>
          <p>650 números</p>
          <button>
            <Svgs nome="mais" />
          </button>
        </div>
        <div class="confirmar">
          <button>
            <p>Participar do Sorteio</p>
            <h4>R$ 95,00</h4>
          </button>
        </div>
      </div>
      <!-- Regulamentos -->
      <DivRegulamento />
      <!-- Premiados -->
      <DivPremiados />
    </div>
  </section>
</template>

<script setup>
import { reactive, onMounted, onUnmounted } from 'vue'
import Svgs from '@svgs'
import SectionImagens from '@components/app/inicio/partials/SectionImagens.vue'
import DivPremiados from '@components/app/inicio/partials/DivPremiados.vue'
import DivRegulamento from '@components/app/inicio/partials/DivRegulamento.vue'

const state = reactive({
  dias: 0,
  horas: 0,
  minutos: 0,
  segundos: 0,
  pacotes: [
    {
      popular: false,
      icone: 'https://arquivos.oficinadasrifas.com/imagens/imagem-rifa-1.png',
      quantidade: '+100'
    },
    {
      popular: true,
      icone: 'https://arquivos.oficinadasrifas.com/imagens/imagem-rifa-2.png',
      quantidade: '+300'
    },
    {
      popular: false,
      icone: 'https://arquivos.oficinadasrifas.com/imagens/imagem-rifa-3.png',
      quantidade: '+500'
    },
    {
      popular: false,
      icone: 'https://arquivos.oficinadasrifas.com/imagens/imagem-rifa-4.png',
      quantidade: '+1000'
    }
  ]
})

const calcularstate = () => {
  const dataFutura = new Date('2025-02-10T18:30:00')
  const agora = new Date()
  const diferenca = dataFutura - agora

  if (diferenca > 0) {
    state.dias = Math.floor(diferenca / (1000 * 60 * 60 * 24))
    state.horas = Math.floor((diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    state.minutos = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60))
    state.segundos = Math.floor((diferenca % (1000 * 60)) / 1000)
  } else {
    state.dias = 0
    state.horas = 0
    state.minutos = 0
    state.segundos = 0
  }
}

let interval

onMounted(() => {
  calcularstate()
  interval = setInterval(calcularstate, 1000)
})

onUnmounted(() => {
  clearInterval(interval)
})
</script>

<style scoped>
section.comprar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 100px 30px 30px 30px;
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(50% - 50px);
  background-color: var(--cor-escuro-1);
  border-radius: 10px;
  margin-left: auto;
}

.topo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--cor-escuro-4);
  background-color: var(--cor-escuro-2);
  padding: 30px;
  border-radius: 10px 10px 0 0;
}

.topo .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.topo .texto h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.topo .texto p {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.topo .tempo {
  display: flex;
  align-items: center;
}

.topo .tempo .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0 10px 0;
  width: 55px;
  border-radius: 5px;
  background-color: var(--cor-escuro-3);
  border-bottom: 2px solid var(--cor-azul);
  margin: 0 0 0 10px;
}

.topo .tempo .item h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.topo .tempo .item p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.quantidade {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 50px 40px;
  background-color: var(--cor-escuro-2);
  border-radius: 0 0 10px 10px;
}

.quantidade .titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 0 50px 0;
}

.quantidade .titulo h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}

.quantidade .titulo p {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
}

.quantidade .itens {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.quantidade .itens .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: calc(25% - 10px);
  background-color: var(--cor-escuro-3);
  border: 1px solid var(--cor-escuro-3);
  border-radius: 10px;
  position: relative;
  padding: 40px 0 0 0;
  cursor: pointer;
  transition: all 0.3s;
}

.quantidade .itens .item:hover {
  background-color: var(--cor-escuro-4);
  border: 1px solid var(--cor-escuro-4);
}

.quantidade .itens .item.popular:hover {
  background-color: var(--cor-escuro-2);
  border: 1px solid var(--cor-branco);
}

.quantidade .itens .item.popular {
  border: 1px solid var(--cor-azul);
  background-color: var(--cor-escuro-1);
}

.quantidade .itens .item .icone {
  width: 60px;
  height: 60px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.quantidade .itens .item h3 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
  margin: 10px 0 30px 0;
}

.quantidade .itens .item h4 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  background-color: var(--cor-azul);
  padding: 7px 15px;
  border-radius: 50px;
  position: absolute;
  top: -15px;
}

.quantidade .itens .item button {
  width: 100%;
  background-color: transparent;
  border-top: 1px solid var(--cor-escuro-4);
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-escuro-5);
  padding: 15px;
  transition: all 0.3s;
}

.quantidade .itens .item.popular:hover button {
  border-top: 1px solid var(--cor-branco);
}

.quantidade .itens .item.popular button {
  color: var(--cor-branco);
  border-top: 1px solid var(--cor-azul);
}

.quantidade .total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--cor-escuro-3);
  border-radius: 10px;
  margin: 20px 0 0 0;
}

.quantidade .total button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: transparent;
  border-radius: 10px;
  transition: all 0.3s;
}

.quantidade .total button:hover {
  background-color: var(--cor-escuro-4);
}

.quantidade .total button svg {
  width: 30px;
  min-width: 30px;
  fill: var(--cor-branco);
}

.quantidade .total p {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
}

.confirmar {
  display: flex;
  width: 100%;
}

.confirmar button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  background-color: var(--cor-verde);
  margin: 20px 0 0 0;
  padding: 25px;
  transition: all 0.3s;
}

.confirmar button:hover {
  background-color: var(--cor-verde-escuro);
}

.confirmar button h4 {
  font-family: var(--regular);
  font-size: var(--f4);
  color: var(--cor-branco);
}

.confirmar button p {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
}

@media screen and (max-width: 1000px) {
  section.comprar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .quadro {
    width: 100%;
    background-color: var(--cor-escuro-1);
    border-radius: 0;
    padding: 20px;
  }

  .topo {
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: 0 0 30px 0;
    border-bottom: none;
    background-color: var(--cor-escuro-1);
  }

  .topo .texto {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 0 10px;
  }

  .topo .texto h3 {
    font-size: var(--f0);
  }

  .topo .texto p {
    font-size: var(--f2);
  }

  .topo .tempo .item {
    padding: 10px 0 8px 0;
    width: 50px;
    margin: 0 10px 0 0;
  }

  .topo .tempo .item h3 {
    font-size: var(--f2);
  }

  .topo .tempo .item p {
    font-size: var(--f1);
  }

  .quantidade {
    width: 100%;
    padding: 30px 20px;
    border-radius: 10px;
  }

  .quantidade .titulo {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 0 40px 0;
  }

  .quantidade .titulo h3 {
    font-size: var(--f2);
  }

  .quantidade .titulo p {
    font-size: var(--f3);
  }

  .quantidade .itens .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: calc(25% - 5px);
    padding: 30px 0 0 0;
  }

  .quantidade .itens .item .icone {
    width: 40px;
    height: 40px;
  }

  .quantidade .itens .item h3 {
    font-size: var(--f3);
    margin: 10px 0 20px 0;
  }

  .quantidade .itens .item h4 {
    font-size: var(--f0);
    padding: 5px 10px;
    border-radius: 50px;
    position: absolute;
    top: -15px;
  }

  .quantidade .itens .item button {
    font-size: var(--f1);
    padding: 13px;
  }

  .quantidade .itens .item.popular button {
    color: var(--cor-branco);
    border-top: 1px solid var(--cor-azul);
  }

  .quantidade .total {
    margin: 10px 0 0 0;
  }

  .quantidade .total button {
    width: 70px;
    height: 70px;
  }

  .quantidade .total button svg {
    width: 20px;
    min-width: 20px;
  }

  .quantidade .total p {
    font-size: var(--f3);
  }

  .confirmar button {
    margin: 10px 0 0 0;
    padding: 20px;
  }

  .confirmar button h4 {
    font-size: var(--f3);
  }

  .confirmar button p {
    font-size: var(--f3);
  }
}
</style>
