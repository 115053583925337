<template>
  <section class="imagens">
    <div class="slide">
      <div class="item" v-for="(imagem, index) in state.imagens" :key="index">
        <div class="imagem" :style="`background-image: url('${imagem.foto}')`"></div>
      </div>
    </div>
    <div class="fundo" style="background-image: url('https://arquivos.oficinadasrifas.com/imagens/carro-maior.png')">
      <div class="sombra">
        <h3>Jetão Suspensão a Ar aro 20</h3>
        <p>Ação autorizada por alvará judicial processo n 1007074-03.2024.8.26.0114</p>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive } from 'vue'

const state = reactive({
  imagens: [
    {
      foto: 'https://arquivos.oficinadasrifas.com/imagens/carro-1.png'
    },
    {
      foto: 'https://arquivos.oficinadasrifas.com/imagens/carro-2.png'
    },
    {
      foto: 'https://arquivos.oficinadasrifas.com/imagens/carro-3.png'
    },
    {
      foto: 'https://arquivos.oficinadasrifas.com/imagens/carro-4.png'
    },
    {
      foto: 'https://arquivos.oficinadasrifas.com/imagens/carro-5.png'
    },
    {
      foto: 'https://arquivos.oficinadasrifas.com/imagens/carro-1.png'
    },
    {
      foto: 'https://arquivos.oficinadasrifas.com/imagens/carro-2.png'
    },
    {
      foto: 'https://arquivos.oficinadasrifas.com/imagens/carro-3.png'
    },
    {
      foto: 'https://arquivos.oficinadasrifas.com/imagens/carro-4.png'
    },
    {
      foto: 'https://arquivos.oficinadasrifas.com/imagens/carro-5.png'
    }
  ]
})
</script>

<style scoped>
section.imagens {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  background-color: var(--cor-escuro-2);
  border-radius: 10px;
  position: fixed;
  top: 100px;
  left: 30px;
  min-height: calc(100dvh - 140px);
  z-index: 10;
}

.fundo {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  z-index: 1;
}

.fundo .sombra {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 30px;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(90deg, #111112cb, #11111200, #111112cb);
  background: -webkit-linear-gradient(90deg, #111112cb, #11111200, #111112cb);
  z-index: 2;
}

.fundo .sombra h3 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
}

.fundo .sombra p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 5px 0 0 0;
}

.slide {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 30px;
  overflow-x: scroll;
  scrollbar-width: none;
  z-index: 3;
}

.slide::-webkit-scrollbar {
  display: none;
}

.slide .item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

.slide .item:hover {
  transform: scale(0.9);
}

.slide .item .imagem {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  transition: all 0.3s;
}

.slide .item:hover .imagem {
  filter: brightness(110%);
}

@media screen and (max-width: 1000px) {
  section.imagens {
    width: 100%;
    height: 350px;
    min-height: 350px;
    position: relative;
    left: 0;
    top: 0;
    z-index: 3;
  }
  .fundo {
    border-radius: 0;
  }

  .fundo .sombra {
    align-items: center;
    border-radius: 0;
    padding: 20px;
    background: linear-gradient(90deg, #111112, #11111200);
    background: -webkit-linear-gradient(90deg, #111112, #11111200);
  }

  .fundo .sombra h3 {
    font-size: var(--f4);
  }

  .fundo .sombra p {
    font-size: var(--f0);
    margin: 8px 0 0 0;
  }

  .slide {
    align-items: center;
    justify-content: center;
    top: auto;
    bottom: 65px;
    left: 0;
    padding: 20px 30px;
    overflow-x: none;
  }

  .slide .item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 0;
    transition: all 0.3s;
  }

  .slide .item:hover {
    transform: scale(0.9);
  }

  .slide .item .imagem {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-image: inherit !important;
    background-color: var(--cor-branco);
    opacity: 0.5;
  }
}
</style>
